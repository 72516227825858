import { useEffect, useCallback, useState } from "react";
import { addDays } from "date-fns";
import { useCookies } from "react-cookie";

import { useTypedSearchParams } from "@rimo/frontend/hooks/useNavigation";
import { hookRequestFormForAffiliate, hookUploadForAffiliate } from "@rimo/frontend/services/conversion/Affiliate";

export const cookieKeyAffiliate = "affiliate-a8" as const;

export const useAffiliateEarner = () => {
  const query = useTypedSearchParams<{ a8?: string }>();
  const [a8Code, setA8Code] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies([cookieKeyAffiliate]);

  const setByQuery = useCallback(() => {
    if (!query.a8) return;
    const expires = addDays(new Date(), 7);
    setCookie(cookieKeyAffiliate, query.a8, { expires });
    setA8Code(query.a8);
  }, [query.a8, setCookie]);

  const setByCookie = useCallback(() => {
    const v = cookies[cookieKeyAffiliate];
    if (!v) return;
    setA8Code(v);
  }, [cookies]);

  useEffect(() => {
    setByQuery();
    setByCookie();
  }, [setByQuery, setByCookie]);

  const reset = useCallback(() => {
    if (!a8Code) return;
    removeCookie(cookieKeyAffiliate);
    setA8Code("");
  }, [removeCookie, a8Code]);

  const sendOnUpload = useCallback(
    async (uid: string) => {
      if (a8Code) {
        await hookUploadForAffiliate(uid, a8Code);
        reset();
      }
    },
    [a8Code, reset]
  );

  const sendOnRequestForm = useCallback(
    async (orderNumber?: string) => {
      if (a8Code && orderNumber) {
        await hookRequestFormForAffiliate(orderNumber, a8Code);
        reset();
      }
    },
    [a8Code, reset]
  );

  return { a8Code, sendOnUpload, sendOnRequestForm };
};
