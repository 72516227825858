import { FirebaseError } from "firebase/app";

// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
// https://firebase.google.com/docs/reference/js/v8/firebase.User
// https://firebase.google.com/docs/auth/admin/errors
function getFirebaseAuthenticationErrorMessage(err: FirebaseError, defaultError: string) {
  switch (err.code) {
    case "auth/account-exists-with-different-credential":
      return "このアカウントは既に登録されています";
    case "auth/email-already-in-use":
      return "このメールアドレスはすでに登録されています。";
    case "auth/email-already-exists":
      return "このメールアドレスはすでに登録されています。";
    case "auth/invalid-email":
      return "メールアドレスの形式が正しくありません。";
    case "auth/network-request-failed":
      return "リクエストが中断されました。ネットワークを確認してください。";
    case "auth/weak-password":
      return "指定したパスワードが弱すぎます。複数の文字を使ってください。(6文字以上)";
    case "auth/user-cancelled":
      return "操作が中断されました。もう一度最初からログインしてください。";
    case "auth/user-disabled":
      return "ユーザーが無効化されている。";
    case "auth/user-not-found":
      return "ユーザが見つかりません。";
    case "auth/user-token-expired":
      return "セッションが切れました。再度ログインしてください。";
    case "auth/too-many-requests":
      return "短時間にログインが何度も試行されたため短期的にアカウントがロックされています。時間をあけて再度実施してください。パスワードをリセットするとすぐにログインできます。";
    case "auth/wrong-password":
      return "パスワードが異なります。"; // このエラーはGoogle認証でアカウント作成したユーザーがメールアドレス＆パスワードでログインを試みようとした場合にも出る。
    case "auth/credential-already-in-use":
      return "選択したメールアドレスは別のユーザに連携されています。";
    case "auth/popup-closed-by-user":
      return "ポップアップが閉じられました。再度実行してください。";
    case "auth/requires-recent-login":
      return "操作を続ける為に再ログインしてください。";
    case "auth/operation-not-allowed":
      return "操作が許可されていません。";
    case "auth/invalid-custom-token":
      return "トークンが無効です";
    default:
      return defaultError;
  }
}

export function createFirebaseAuthenticateErrorMessage(
  err: unknown,
  defaultError = "ユーザー情報の取得中にエラーが発生しました。"
): [Error, boolean] {
  if (err instanceof FirebaseError) {
    return [new Error(getFirebaseAuthenticationErrorMessage(err, defaultError)), true];
  } else {
    return [new Error(getFirebaseAuthenticationErrorMessage({ code: "" } as FirebaseError, defaultError)), false];
  }
}
