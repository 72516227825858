import { client } from ".";

type EmailValidateResult = {
  passwordLoginAcceptable: boolean;
  providers: string[];
  samlProviderId?: string | undefined;
  notExists: boolean;
};

type Response = {
  password_login_acceptable: boolean;
  providers: string[];
  saml_provider_id?: string | undefined;
  not_exists: boolean;
};

export default {
  validateEmail: async (
    email: string,
    option: {
      includeInvitations: boolean;
    } = {
      includeInvitations: false,
    }
  ): Promise<EmailValidateResult> => {
    const { data: d } = await client.post<Response>("/email/validate", {
      email,
      include_invitations: option.includeInvitations,
    });
    return {
      passwordLoginAcceptable: d?.password_login_acceptable || false,
      providers: d?.providers || [],
      samlProviderId: d?.saml_provider_id || undefined,
      notExists: d?.not_exists || false,
    };
  },
};
