import { useRouter } from "next/navigation";
import { useContext, useCallback } from "react";

import { useNotificationDispatcher } from "../contexts/NotificationContext";
import { UserContext } from "../contexts/UserContext";
import { UserExtraInfoAuthResult } from "../services/firebase/auth/user";
import { Constant } from "../constants/constant";

export const useInjectUserIntoReactApp = () => {
  const router = useRouter();
  const dispatch = useNotificationDispatcher();
  const { userDispatch } = useContext(UserContext);
  const notify = useCallback((message: string) => dispatch({ type: "INFO", payload: { message } }), [dispatch]);
  const inject = useCallback(
    (result: UserExtraInfoAuthResult) => {
      console.debug("[useInjectUserIntoReactApp] Result", result);

      // Reactにログインユーザー情報を渡す
      userDispatch({ type: "LOGIN", payload: result.userExtra });

      const { kind, acceptedOrganization: org, redirectTo = Constant.Url.Internal.TopPage } = result;

      // 通知処理
      switch (kind) {
        case "custom":
        case "reload":
          break;
        case "signUp":
          notify("ユーザー登録が完了しました");
          break;
        case "signIn":
          if (org) {
            notify(`${org.formal_name || org.name}に加入しました`);
          } else {
            notify("ログインしました");
          }
          break;
        default:
          break;
      }

      // カスタムトークンによるログインはページ遷移しない
      if (kind === "custom") {
        return;
      }
      // リロード時はページ遷移しない
      if (kind === "reload") {
        return;
      }

      console.debug("redirectTo", redirectTo);

      // 画面遷移処理
      router.replace(redirectTo);
    },
    [router, userDispatch, notify]
  );

  return inject;
};
