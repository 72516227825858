export type SessionStorageKey = "redirectTo" | "invitationConfirming";

type Primitive = number | string | boolean | null | undefined;

export class SessionStorage {
  private static validate() {
    const isBrowser = typeof window !== "undefined";
    if (!isBrowser) throw new Error("required to set value into SessionStorage on client-side");
  }

  static clear() {
    this.validate();
    window.sessionStorage.clear();
  }

  static setItem(key: SessionStorageKey, value: Primitive): void {
    this.validate();
    if (value === undefined) return window.sessionStorage.removeItem(key);
    if (value === null) return window.sessionStorage.removeItem(key);
    if (value === true) return window.sessionStorage.setItem(key, "true");
    if (value === false) return window.sessionStorage.setItem(key, "false");
    if (typeof value === "number") return window.sessionStorage.setItem(key, value.toString());
    if (typeof value === "string" && Boolean(value)) return window.sessionStorage.setItem(key, value);
  }

  static getItem(key: SessionStorageKey): Exclude<Primitive, undefined> {
    this.validate();

    const value = window.sessionStorage.getItem(key);

    if (value === null) return null;
    if (value === "true") return true;
    if (value === "false") return false;
    if (!isNaN(parseInt(value))) return parseInt(value);
    if (typeof value === "string") return value;

    throw new Error("not found value in SessionStorage");
  }
}
