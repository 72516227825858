import React from "react";

type Role = "info" | "success" | "warning" | "error";

interface StateProps {
  message: string;
  isOpen: false;
  timeout: number;
  severity: Role;
  version?: string;
}

interface ActionProps {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

const reducer: React.Reducer<StateProps, ActionProps> = (state, action) => {
  switch (action.type) {
    case "ADD":
      if (!action.payload.severity) {
        return {
          ...state,
          isOpen: true,
          severity: "info",
          timeout: defaultTimeout,
          ...action.payload,
        };
      }
      return {
        ...state,
        isOpen: true,
        timeout: defaultTimeout,
        ...action.payload,
      };
    case "CLOSE":
      return {
        ...state,
        isOpen: false,
        timeout: defaultTimeout,
      };
    case "SUCCESS": {
      return {
        ...state,
        isOpen: true,
        severity: "success",
        timeout: defaultTimeout,
        ...action.payload,
      };
    }
    case "ERROR": {
      return {
        ...state,
        isOpen: true,
        severity: "error",
        timeout: defaultTimeout,
        ...action.payload,
      };
    }
    case "WARN": {
      return {
        ...state,
        isOpen: true,
        severity: "warning",
        timeout: defaultTimeout,
        ...action.payload,
      };
    }
    case "INFO": {
      return {
        ...state,
        isOpen: true,
        severity: "info",
        timeout: defaultTimeout,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

const defaultTimeout = 3000;

const initialState: StateProps = {
  message: "",
  isOpen: false,
  timeout: defaultTimeout,
  severity: "info",
};

export const NotificationStateContext = React.createContext<StateProps>(initialState);

export const NotificationDispatchContext = React.createContext<React.Dispatch<ActionProps>>((() => {
  // noop
}) as React.Dispatch<ActionProps>);

export const NotificationProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>{children}</NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};

export function useNotificationDispatcher() {
  return React.useContext(NotificationDispatchContext);
}
