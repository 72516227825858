import { AffiliateProgramID } from "@rimo/frontend/constants/services";
import axios from "axios";

// @see https://document.a8.net/a8docs/a8-tracking/session-tracking/a8-session-tracking.html
export async function hookUploadForAffiliate(userID: string, a8?: string) {
  const code = "1-1-1-upload";
  await sendAffiliate(code, userID, a8);
}

export async function hookRequestFormForAffiliate(orderNumber?: string, a8?: string) {
  const code = "1-1-1-document";
  await sendAffiliate(code, orderNumber, a8);
}

export async function sendAffiliate(code: string, orderNumber?: string, a8?: string) {
  if (!a8 || !AffiliateProgramID || !orderNumber) {
    return;
  }
  // https://px.a8.net/a8fly/earnings?a8={識別パラメータ}&pid=s00000023714001&so={注文番号}&si=1-1-1-document
  try {
    await axios.get(`https://px.a8.net/a8fly/earnings?a8=${a8}&pid=${AffiliateProgramID}&so=${orderNumber}&si=${code}`);
  } catch (e) {
    console.error("a8 error", e, e.response?.status, e.reponse?.data);
  }

  return null;
}
