import {
  UserCredential,
  getAdditionalUserInfo,
  getIdTokenResult,
  sendEmailVerification,
  updateProfile,
  User,
} from "firebase/auth";

import Sentry from "@rimo/frontend/utils/sentry";
import userApi from "@rimo/frontend/api/user";
import { notifySignUp } from "./signUp";
import { autoAcceptOrgInvitationWithSessionStorage } from "./signIn";
import { UserExtraInfoAuthResult } from "./user";
import { SessionStorage } from "../../storage/SessionStorage";

/**
 * ユーザー登録時の共通処理を記述
 * @param credential ReturnType<typeof getRedirectResult> | ReturnType<typeof createUserWithEmailAndPassword>
 * @returns Promise<UserExtraInfoAuthResult>
 */
export async function onSignUp(
  credential: UserCredential,
  options: {
    /** 登録用ユーザー名(新規登録時にユーザー名を更新時のみ) */
    userName?: string;
  }
): Promise<UserExtraInfoAuthResult> {
  const prefix = "[Firebase Auth onSignUp]";
  const { user } = credential;
  const userInfo = getAdditionalUserInfo(credential);

  if (!userInfo) throw new Error("Not found userInfo by Firebase getAdditionalUserInfo()");
  if (!userInfo.isNewUser) throw new Error("isNewUser is False onSignUp"); // 既にユーザー登録している場合(ログイン時)

  // ユーザー名登録処理(Eメール、SAML時)
  if (options?.userName) {
    await updateProfile(user, { displayName: options.userName })
      .then(() => {
        console.debug(prefix, "ユーザー名を登録しました", options.userName);
      })
      .catch((err) => {
        // ユーザー名の更新処理失敗だけでユーザー登録自体を失敗にさせない
        Sentry.captureException(err, {
          contexts: {
            user: {
              uid: user.uid,
              email: user.email,
              emailVerified: user.emailVerified,
              name: options.userName,
            },
          },
        });
      });
  }

  // 仮ユーザ登録完了メール送信(メールアドレス・パスワードでのユーザー登録)
  if (userInfo.providerId === "password") {
    await sendEmailVerification(user, {
      url: "https://rimo.app/notes",
      handleCodeInApp: false,
    }).catch((err) => {
      // メール送信に失敗した場合は、、現在は emailVerifiedプロパティを確認していないので、問題はない
      // 再送信用のUIが今後必要になるかもしれない
      Sentry.captureException(err, {
        contexts: {
          user: {
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            name: options.userName,
          },
        },
      });
    });
    console.debug(prefix, "ユーザーにメールアドレス確認用メールの送信処理が終了しました");
  }

  // rimo-backendで様々な初期設定が実施される
  await userApi.signUp(await user.getIdToken());

  // forceRefreshの上、idTokenを取得する
  // ※ signUp()でFirebase Authenticationのclaims等が更新される可能性があるため
  const idToken = await getIdTokenResult(user, true);

  // コンバージョン
  await notifySignUp(user, userInfo, idToken);

  console.debug(prefix, `ProviderId='${userInfo?.providerId}')`, "サインアップ処理完了", credential);

  return {
    userExtra: { user, extra: userInfo, idToken },
    kind: "signUp",
    redirectTo: getRedirectToWithSessionStorage(),
  };
}

/**
 * ユーザーがログインをした時に実行する共通処理
 * @param credential UserCredential
 * @returns Promise<UserExtraInfoAuthResult>
 */
export async function onSignIn(credential: UserCredential): Promise<UserExtraInfoAuthResult> {
  const prefix = "[Firebase Auth onSignIn]";
  const { user } = credential;
  const userInfo = getAdditionalUserInfo(credential);

  if (!userInfo) throw new Error("Not found userInfo by Firebase getAdditionalUserInfo()");
  if (userInfo.isNewUser) throw new Error("isNewUser is True onSignIn"); // ユーザー登録だった場合(サインアップ時)

  // 招待処理のためにJWT用トークンを取得する
  const idToken = await getIdTokenResult(user);

  // 招待自動承認処理を実施(あれば)
  const team = await autoAcceptOrgInvitationWithSessionStorage(idToken);

  // 企業に所属した時はclaims等が書き換わる可能性があるため、forceRefreshさせる)
  const newIdToken = team ? await getIdTokenResult(user, true) : idToken;

  // ユーザー登録時に失敗した時用
  await notifySignUp(user, userInfo, newIdToken);

  // userInfo?.providerIdはstring(optional)だが、 credential.user.providerIdはstringで使える
  console.debug(prefix, `ProviderId='${userInfo?.providerId}')`, credential);

  return {
    userExtra: { user, extra: userInfo, idToken: newIdToken },
    kind: "signIn",
    redirectTo: getRedirectToWithSessionStorage(),
    acceptedOrganization: team,
  };
}

/**
 * ユーザーがページをリロードした時、セッション情報が残っていた場合に実行する共通処理
 * extra(AdditionalUserInfo)はサインアップ・サインイン時にのみ取得できる様子
 * @param user User
 * @param forceRefresh 強制リフレッシュするかどうか
 * @returns Promise<UserExtraInfoAuthResult>
 */
export async function onPageReload(user: User, forceRefresh = false): Promise<UserExtraInfoAuthResult> {
  const idToken = await getIdTokenResult(user, forceRefresh);
  const provider = idToken.signInProvider;
  const kind = provider === "custom" ? "custom" : "reload";
  return {
    userExtra: {
      extra: null,
      user,
      idToken,
    },
    kind,
    redirectTo: undefined,
  };
}

function getRedirectToWithSessionStorage(): string | undefined {
  const value = SessionStorage.getItem("redirectTo");
  const isValid = typeof value === "string" && Boolean(value);

  SessionStorage.setItem("redirectTo", null);

  return isValid ? value : undefined;
}
